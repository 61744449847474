import { Typography, styled } from '@mui/material';

import { Domain } from './Domain';
import { pxToRem } from '../../../utils/style-functions';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';

const Wrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    padding: theme.spacing(3, 6, 3, 3),
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
    minWidth: 444,
    maxWidth: 655,
    margin: 0,
    [theme.breakpoints.down(1440)]: {
        margin: theme.spacing(0, 4),
        width: '100%',
        maxWidth: 640,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 10,
        padding: theme.spacing(3),
        minWidth: 0,
    },
    [theme.breakpoints.down(744)]: {
        margin: theme.spacing(0, 2),
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(24),
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(18),
    },
}));

interface IOwnProps {
    topDomainText: string;
}

export const DomainSidebar: React.FC<IOwnProps> = ({ topDomainText }) => {
    const {
        state: { cognitiveMap, openDomainDrawerId },
    } = useCognitiveMap();

    return (
        <Wrapper id='domain-sidebar'>
            <Title variant='h4'>
                {cognitiveMap?.staticContent.sidePanelTitle}
            </Title>
            <div>
                {cognitiveMap?.domains.map((domain) => (
                    <Domain
                        isOpen={openDomainDrawerId === domain.domainId}
                        key={domain.domainId}
                        domain={domain}
                        topDomainText={topDomainText}
                    />
                ))}
            </div>
        </Wrapper>
    );
};
