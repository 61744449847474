import {
    DomainsMap,
    IAssignedModulesResponse,
    ICategoryDeepDiveResponse,
    ICognitiveMap,
    ICognitiveMapCoursesResponse,
    IContextualisedStrategiesResponse,
    IDashboardData,
    IDiversityIndexData,
    IDomainDataResponse,
    IEmailValidationResponse,
    IGetCategoriesResponse,
    IGetCognitiveDomainZScoresResponse,
    IGetExistingContextualisedStrategyResponse,
    IGetFilterCategories,
    IGetFilterSkills,
    IGetLearnerAssessmentStatusResponse,
    IGetLearnerResponse,
    IGetLearnerUrl,
    IGetUserManagementMetadataResponse,
    IHealthDataLegalDocumentsResponse,
    IInvitationMetadataResponse,
    ILearnerAssessentRestartResponse,
    ILearnerDetailsRequestBody,
    ILearnerPreAssessmentDetailsRequestBody,
    ILearnerUserData,
    INewReportConsent,
    IOnboardingLinkResponse,
    IPostLearningPlanRequest,
    IPostLearningPlanResponse,
    IPriorityModule,
    IPriorityModulesResponse,
    IProcessingBiasData,
    IPutPriorityModulesRequest,
    IPutPriorityModulesResponse,
    IPutRegisterAsessmentRequest,
    IReportConsent,
    ISelfOnboardingResponse,
    IStrategysResponse,
    IUpdatePasswordRequestData,
    IUsersResponse,
    ReportType,
    UserResponse,
} from '../types';
import {
    ICanUserVolunteerAsChampionResponse,
    IChampionProfileRequestBody,
    IChampionProfileResponse,
    IChampionRequestParams,
    IChampionSupportRequestBody,
    IChampionUserProfileResponse,
    IChampionsResponse,
    IChampionsUserStatusResponse,
    ICoachResponse,
    IEnterpriseUser,
    IEnterpriseUserBody,
    IManagerUpdateBody,
    IManagerUpdateResponse,
    INewChampionSupportRequestsCountResponse,
    INewChampionVolunteerCountResponse,
    ISecondaryManagerUpdateBody,
    ISecondaryManagerUpdateResponse,
    ISupportRequestResponse,
    ISupportRequestsParams,
    ISupportRequestsResponse,
    IUploadChampionProfilePictureResponse,
    IUserCompletedCourses,
    IUserFilters,
    SupportRequestStatus,
} from '../types/user.types';

import { callApi } from '../utils/callApi';
import { callApiFetch } from '../utils/callApiFetch';

export const endpoints = () => {
    const withCredentials =
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'localhost';

    const getUser = async () => {
        return await callApi<UserResponse>({
            method: 'GET',
            url: '/auth/getUser',
            withCredentials,
        });
    };

    const getLearnerDetails = async (learnerUserId: string) => {
        return await callApi<IGetLearnerResponse>({
            method: 'GET',
            url: `learners/${learnerUserId}`,
            withCredentials,
        });
    };

    const putLearnerPreAssessmentDetails = async ({
        learnerUserId,
        data,
    }: {
        learnerUserId: string;
        data: ILearnerPreAssessmentDetailsRequestBody;
    }) => {
        return await callApi<null, ILearnerPreAssessmentDetailsRequestBody>({
            method: 'PUT',
            url: `learners/${learnerUserId}/preassessment`,
            withCredentials,
            data,
        });
    };

    const putLearnerDetails = async <T extends string = string>({
        learnerUserId,
        data,
    }: {
        learnerUserId: string;
        data: ILearnerDetailsRequestBody;
    }) => {
        return await callApi<null, ILearnerDetailsRequestBody, T>({
            method: 'PUT',
            url: `learners/${learnerUserId}`,
            withCredentials,
            data,
        });
    };

    const getCategories = async (learnerUserId: string) => {
        return await callApi<IGetCategoriesResponse>({
            url: `learnerreports/${learnerUserId}/categories`,
            method: 'GET',
            withCredentials,
        });
    };

    const getDomainData = async (learnerUserId: string) => {
        return await callApi<IDomainDataResponse>({
            url: `learnerreports/${learnerUserId}/domaindata`,
            method: 'GET',
            withCredentials,
        });
    };

    const learnerReportViewed = async (learnerUserId: string) => {
        return await callApi<never>({
            url: `learnerreports/${learnerUserId}/reportviewings`,
            method: 'POST',
            withCredentials,
        });
    };

    const getCategoryDeepDive = async ({
        learnerUserId,
        categoryId,
    }: {
        learnerUserId: string;
        categoryId: string;
    }) => {
        return await callApi<ICategoryDeepDiveResponse>({
            url: `learnerreports/${learnerUserId}/categories/${categoryId}`,
            method: 'GET',
            withCredentials,
        });
    };

    const getAssignedModules = async ({
        learnerUserId,
        completedOnly = false,
        pageIndex,
        pageSize,
        currentMonthOnly = false,
    }: {
        learnerUserId: string;
        completedOnly?: boolean;
        pageIndex: number;
        pageSize: number;
        currentMonthOnly?: boolean;
    }) => {
        return await callApi<IAssignedModulesResponse>({
            url: `learnermodules/${learnerUserId}/assignedmodules`,
            method: 'GET',
            params: {
                completedOnly,
                currentMonthOnly,
                pageIndex,
                pageSize,
            },
            withCredentials,
        });
    };

    const getPriorityModules = async (learnerUserId: string) => {
        return await callApi<IPriorityModulesResponse>({
            url: `learnermodules/${learnerUserId}/prioritymodules`,
            method: 'GET',
            withCredentials,
        });
    };

    const postLearningPlan = async ({
        learnerUserId,
        pageIndex,
        pageSize,
        categories,
        skills,
    }: {
        learnerUserId: string;
        pageIndex: number;
        pageSize: number;
        categories: string[];
        skills: string[];
    }) => {
        return await callApi<
            IPostLearningPlanResponse,
            IPostLearningPlanRequest
        >({
            url: `learnermodules/${learnerUserId}/learningplan`,
            method: 'POST',
            params: {
                pageIndex,
                pageSize,
            },
            data: {
                categoryIds: categories,
                skillIds: skills,
            },
            withCredentials,
        });
    };

    const putPriorityModules = async ({
        learnerUserId,
        timestamp,
        modules,
    }: {
        learnerUserId: string;
        timestamp: string;
        modules: Pick<IPriorityModule, 'id' | 'order'>[];
    }) => {
        return await callApi<
            IPutPriorityModulesResponse,
            IPutPriorityModulesRequest
        >({
            url: `learnermodules/${learnerUserId}/prioritymodules`,
            method: 'PUT',
            data: {
                timestamp,
                modules,
            },
            withCredentials,
        });
    };

    const getFilterSkills = async () => {
        return await callApi<IGetFilterSkills>({
            url: 'lookups/skills',
            method: 'GET',
            withCredentials,
        });
    };

    const getFilterCategories = async () => {
        return await callApi<IGetFilterCategories>({
            url: 'lookups/categories',
            method: 'GET',
            withCredentials,
        });
    };

    const getUserData = async (learnerUserId: string) => {
        return await callApi<ILearnerUserData>({
            url: `users/${learnerUserId}`,
            method: 'GET',
            withCredentials,
        });
    };

    const getUserCompletedCourses = async (learnerUserId: string) => {
        return await callApi<IUserCompletedCourses>({
            url: `users/${learnerUserId}/courses`,
            method: 'GET',
            withCredentials,
        });
    };

    const getProcessingBiasData = async ({
        learnerUserId,
        reportType,
    }: {
        learnerUserId: string;
        reportType: ReportType;
    }) => {
        return await callApi<IProcessingBiasData>({
            url: `/learnerreports/${learnerUserId}/processingbias/${reportType}`,
            method: 'GET',
            withCredentials,
        });
    };

    const getDiversityIndexData = async ({
        learnerUserId,
        reportType,
    }: {
        learnerUserId: string;
        reportType: ReportType;
    }) => {
        return await callApi<IDiversityIndexData>({
            url: `/learnerreports/${learnerUserId}/diversityindex/${reportType}`,
            method: 'GET',
            withCredentials,
        });
    };

    const getReportConsent = async (learnerUserId: string) => {
        return await callApi<IReportConsent>({
            url: `learnerreports/${learnerUserId}/consent`,
            method: 'GET',
            withCredentials,
        });
    };

    const patchNewReportConsent = async ({
        learnerUserId,
        newConsentStatus,
    }: {
        learnerUserId: string;
        newConsentStatus: boolean;
    }) => {
        return await callApi<null, INewReportConsent>({
            url: `learnerreports/${learnerUserId}/consent`,
            method: 'PATCH',
            data: {
                newConsentStatus,
            },
            withCredentials,
        });
    };

    const getSignupDetails = async ({ shortId }: { shortId: string }) => {
        return await callApi<ISelfOnboardingResponse>({
            url: `signups/${shortId}`,
            method: 'GET',
        });
    };

    const getDashboardGraphs = async () => {
        return await callApi<IDashboardData>({
            url: `dashboard`,
            method: 'GET',
            withCredentials,
        });
    };

    const getHealthDataLegalDocuments = async () => {
        return await callApi<IHealthDataLegalDocumentsResponse>({
            url: `legaldocuments/healthdata`,
            method: 'GET',
            withCredentials,
        });
    };

    const putRegisterAssessment = async ({
        learnerUserId,
    }: {
        learnerUserId: string;
    }) => {
        return await callApi<IPutRegisterAsessmentRequest, null>({
            url: `learners/${learnerUserId}/assessment`,
            method: 'PUT',
            withCredentials,
        });
    };

    const getLearnerAssessmentStatus = async (learnerUserId: string) => {
        return await callApi<IGetLearnerAssessmentStatusResponse>({
            url: `learners/${learnerUserId}/assessmentstatus`,
            method: 'GET',
            withCredentials,
        });
    };

    const createLearnerOnboarding = async ({
        clientId,
        friendlyClientId,
        email,
        firstName,
        lastName,
        password,
        selfOnBoardingDomains,
        legalDocumentIds,
        useSingleSignOn,
    }: {
        clientId: string;
        friendlyClientId: string;
        email: string;
        firstName: string;
        lastName: string;
        password: string | null;
        selfOnBoardingDomains: string;
        legalDocumentIds: string[];
        useSingleSignOn: boolean;
    }) => {
        return await callApi({
            url: `${clientId}/learners/onboarding`,
            method: 'POST',
            data: {
                email,
                firstName,
                lastName,
                password,
                friendlyString: friendlyClientId,
                selfOnBoardingDomains,
                legalDocumentIds,
                useSingleSignOn,
            },
            withCredentials,
        });
    };

    const checkValidEmail = async ({
        clientId,
        email,
        friendlyString,
        onlyAllowInvitedUsers,
    }: {
        clientId: string;
        email: string;
        friendlyString: string;
        onlyAllowInvitedUsers: boolean;
    }) => {
        return await callApi<IEmailValidationResponse>({
            url: `${clientId}/users/emailvalidation`,
            method: 'POST',
            data: {
                email,
                friendlyString,
                onlyAllowInvitedUsers,
            },
        });
    };

    const getUsers = async (params: IUserFilters) => {
        return await callApi<IUsersResponse, IUserFilters>({
            url: `users`,
            method: 'GET',
            params,
            withCredentials,
        });
    };

    const getEnterpriseUser = async ({ userId }: { userId: string }) => {
        return await callApi<IEnterpriseUser>({
            url: `users/${userId}/enterprise`,
            method: 'GET',
            withCredentials,
        });
    };

    const getCoaches = async () => {
        return await callApi<ICoachResponse>({
            url: `coaches`,
            method: 'GET',
            withCredentials,
        });
    };

    const getUserManagementMetaData = async ({
        userId,
    }: {
        userId: string | undefined;
    }) => {
        return await callApi<IGetUserManagementMetadataResponse>({
            url: `usermanagementmetadata/${userId}`,
            method: 'GET',
            withCredentials,
        });
    };

    const getLearningUrl = async (redirectUrl?: string) => {
        return await callApi<IGetLearnerUrl>({
            url: `learningmanagement`,
            method: 'POST',
            data: {
                redirectUrl,
            },
            withCredentials,
        });
    };

    const enrolUserToCourse = async (learnWorldsCourseId: string) => {
        return await callApi<unknown, { learnWorldsCourseId: string }>({
            url: `learningmanagement/enrol`,
            method: 'POST',
            data: {
                learnWorldsCourseId,
            },
            withCredentials,
        });
    };

    const editUser = async <T extends string = string>({
        id: userId,
        ...rest
    }: { id: string } & IEnterpriseUserBody) => {
        return await callApi<IGetLearnerUrl, unknown, T>({
            url: `users/${userId}`,
            method: 'PUT',
            data: {
                ...rest,
            },
            withCredentials,
        });
    };

    const getOnboardingLink = async () => {
        return await callApi<IOnboardingLinkResponse>({
            url: `onboardinglink`,
            method: 'GET',
            withCredentials,
        });
    };

    const getInvitationMetadata = async () => {
        return await callApi<IInvitationMetadataResponse>({
            url: 'invitationmetadata',
            method: 'GET',
            withCredentials,
        });
    };

    const deleteUser = async <T extends string = string>(userId: string) => {
        return await callApi<IGetLearnerUrl, unknown, T>({
            url: `users/${userId}`,
            method: 'DELETE',
            withCredentials,
        });
    };

    const updateManager = async <T extends string = string>(
        data: IManagerUpdateBody
    ) => {
        return await callApi<IManagerUpdateResponse, IManagerUpdateBody, T>({
            url: `users/primarycoach`,
            method: 'PATCH',
            data,
            withCredentials,
        });
    };

    const updateSecondaryManager = async <T extends string = string>(
        data: ISecondaryManagerUpdateBody
    ) => {
        return await callApi<
            ISecondaryManagerUpdateResponse,
            ISecondaryManagerUpdateBody,
            T
        >({
            url: `users/secondarycoach`,
            method: 'PATCH',
            data,
            withCredentials,
        });
    };

    const getCertifiedChampions = async (params: IChampionRequestParams) => {
        return await callApi<IChampionsResponse>({
            url: `champions/certified`,
            method: 'GET',
            params,
            withCredentials,
        });
    };

    const getUserChampionStatus = async () => {
        return await callApi<IChampionsUserStatusResponse>({
            url: `champions/my/status`,
            method: 'GET',
            withCredentials,
        });
    };

    const getNewChampionSupportRequestsCount = async () => {
        return await callApi<INewChampionSupportRequestsCountResponse>({
            url: `champions/my/supportrequests/new/count`,
            method: 'GET',
            withCredentials,
        });
    };

    const getNewChampionVolunteerCount = async () => {
        return await callApi<INewChampionVolunteerCountResponse>({
            url: `champions/volunteer/count`,
            method: 'GET',
            withCredentials,
        });
    };

    const createChampionSupportRequest = async <T extends string = string>({
        championUserId,
        ...rest
    }: { championUserId: string } & IChampionSupportRequestBody) => {
        return await callApi<null, unknown, T>({
            url: `champions/${championUserId}/supportrequests`,
            method: 'POST',
            data: {
                ...rest,
            },
            withCredentials,
        });
    };

    const deleteChampionSupportRequest = async (
        championUserId: string,
        supportRequestId: string
    ) => {
        return await callApi({
            url: `champions/${championUserId}/supportrequests/${supportRequestId}`,
            method: 'DELETE',
            withCredentials,
        });
    };

    const getSupportRequests = async (params: ISupportRequestsParams) => {
        return await callApi<ISupportRequestsResponse>({
            url: `champions/my/supportrequests`,
            method: 'GET',
            params,
            withCredentials,
        });
    };

    const getSupportRequest = async (supportRequestId: string) => {
        return await callApi<ISupportRequestResponse>({
            url: `champions/my/supportrequests/${supportRequestId}`,
            method: 'GET',
            withCredentials,
        });
    };

    const patchSupportRequestStatus = async (
        supportRequestId: string,
        status: SupportRequestStatus
    ) => {
        return await callApi({
            url: `champions/my/supportrequests/${supportRequestId}/status`,
            method: 'PATCH',
            data: {
                status,
            },
            withCredentials,
        });
    };

    const createChampionSupportRequestNote = async (
        supportRequestId: string,
        note: string
    ) => {
        return await callApi({
            url: `champions/my/supportrequests/${supportRequestId}/notes`,
            method: 'POST',
            data: {
                note,
            },
            withCredentials,
        });
    };

    const getChampionProfile = async (championUserId: string) => {
        return await callApi<IChampionProfileResponse>({
            url: `champions/${championUserId}/profile`,
            method: 'GET',
            withCredentials,
        });
    };

    const uploadChampionProfilePicture = async (profilePicture: File) => {
        const formData = new FormData();
        formData.append('profilePicture', profilePicture);

        return await callApi<IUploadChampionProfilePictureResponse>({
            url: 'champions/my/profile/picture',
            method: 'POST',
            data: formData,
            withCredentials,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    };

    const canUserVolunteerAsChampion = async () => {
        return await callApi<ICanUserVolunteerAsChampionResponse>({
            url: 'champions/my/volunteerstatus',
            method: 'GET',
            withCredentials,
        });
    };

    const volunteerAsChampion = async () => {
        return await callApi({
            url: 'champions/volunteer',
            method: 'POST',
            withCredentials,
        });
    };

    const approveChampionVolunteer = async (championUserId: string) => {
        return await callApi({
            url: `champions/${championUserId}/approve`,
            method: 'PUT',
            withCredentials,
        });
    };

    const patchClientOnlyAllowInvitedUsers = async ({
        onlyAllowInvitedUsers,
    }: {
        onlyAllowInvitedUsers: boolean;
    }) => {
        return await callApi<{ OnlyAllowInvitedUsers: boolean }>({
            url: '/invitationonly',
            method: 'PATCH',
            data: {
                onlyAllowInvitedUsers,
            },
            withCredentials,
        });
    };

    const putSelfOnboardingInvitations = async ({
        emailAddresses,
    }: {
        emailAddresses: string[];
    }) => {
        return await callApi<{ emailAddresses: string[] }>({
            url: '/invitations',
            method: 'PUT',
            data: {
                emailAddresses,
            },
            withCredentials,
        });
    };

    const getCognitiveMapContent = async () => {
        return await callApi<ICognitiveMap>({
            url: '/cognitivemap',
            method: 'GET',
            withCredentials,
        });
    };

    const verifyEmail = async <T extends string = string>(token: string) => {
        return await callApi<null, unknown, T>({
            url: `/emailverifications/${token}`,
            method: 'PUT',
            withCredentials,
        });
    };

    const updatePassword = async ({
        learnerUserId,
        data,
    }: {
        learnerUserId: string;
        data: IUpdatePasswordRequestData;
    }) => {
        return await callApi<{
            learnerUserId: string;
            data: IUpdatePasswordRequestData;
        }>({
            url: `/users/${learnerUserId}/password`,
            method: 'POST',
            data,
            withCredentials,
        });
    };

    const resendVerificationEmail = async () => {
        return await callApi({
            url: `/emailverifications/resend`,
            method: 'POST',
            withCredentials,
        });
    };

    const getCognitiveMapDomainDifferenceZScores = async (
        learnerUserId: string
    ) => {
        return await callApi<IGetCognitiveDomainZScoresResponse>({
            method: 'GET',
            url: `cognitivemap/${learnerUserId}/domaindifferencezscores`,
            withCredentials,
        });
    };

    const getUserDomainStrategies = async ({
        domainId,
        pageIndex,
        pageSize = 12,
    }: {
        domainId: string;
        pageIndex: number;
        pageSize?: number;
    }) => {
        return await callApi<ICognitiveMapCoursesResponse>({
            method: 'GET',
            url: `domains/${domainId}/strategies`,
            params: {
                pageIndex,
                pageSize,
            },
            withCredentials,
        });
    };

    const getDomains = async () => {
        return await callApi<DomainsMap>({
            method: 'GET',
            url: `cognitivedomain`,
            withCredentials,
        });
    };

    const getProfilePicture = async (fileName: string) => {
        return await callApi<Blob>({
            url: `/profilepictures/${fileName}`,
            method: 'GET',
            withCredentials,
            responseType: 'blob',
        });
    };

    const getChampionUserProfile = async () => {
        return await callApi<IChampionUserProfileResponse>({
            url: `champions/my/profile`,
            method: 'GET',
            withCredentials,
        });
    };

    const deleteChampionProfilePicture = async () => {
        return await callApi({
            url: 'champions/my/profile/picture',
            method: 'DELETE',
            withCredentials,
        });
    };

    const patchChampionProfile = async (data: IChampionProfileRequestBody) => {
        return await callApi({
            url: `champions/my/profile`,
            method: 'PATCH',
            data,
            withCredentials,
        });
    };

    const getUserNeuroVersityCourses = async ({
        userId,
        pageIndex,
        pageSize = 12,
        filterText,
    }: {
        userId: string;
        pageIndex: number;
        pageSize?: number;
        filterText?: string;
    }) => {
        return await callApi<IStrategysResponse>({
            method: 'GET',
            url: `userstrategies/${userId}`,
            params: {
                pageIndex,
                pageSize,
                filterText,
            },
            withCredentials,
        });
    };

    const cognassistantQuery = async (message: string, sessionId: string) => {
        return await callApiFetch('aiassistant/userquery', {
            method: 'POST',
            credentials: withCredentials ? 'include' : 'same-origin',
            body: JSON.stringify({ message, session_id: sessionId }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        });
    };

    const getContextualisedStrategy = async (
        userId: string,
        lmsSourceId: string
    ) => {
        return await callApiFetch(
            `users/${userId}/strategies/${lmsSourceId}/contextualise`,
            {
                method: 'POST',
                credentials: withCredentials ? 'include' : 'same-origin',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            }
        );
    };

    const generateCommunityDisplayName = async () => {
        return await callApi<{
            displayName: string;
        }>({
            url: 'amity/displayname/generate',
            method: 'GET',
            withCredentials,
        });
    };

    const checkIfCommunityDisplayNameIsUsed = async (displayName: string) => {
        return await callApi<{ isUsed: boolean }>({
            url: `amity/displayname/isused`,
            method: 'GET',
            params: {
                displayName,
            },
            withCredentials,
        });
    };

    const getCommunityDisplayName = async () => {
        return await callApi<{
            displayName: string | null;
        }>({
            url: 'amity/my/displayname',
            method: 'GET',
            withCredentials,
        });
    };

    const getOrganisationCommunityDisplayName = async () => {
        return await callApi<{
            communityDisplayName: string | null;
        }>({
            url: 'amity/community/organisation/displayname',
            method: 'GET',
            withCredentials,
        });
    };

    const getCommunityAccessToken = async () => {
        return await callApi<{
            accessToken: string;
            apiKey: string;
        }>({
            url: 'amity/my/token',
            method: 'GET',
            withCredentials,
        });
    };

    const createCommunityAccount = async ({
        displayName,
    }: {
        displayName: string;
    }) => {
        return await callApi({
            url: `amity/my/account`,
            method: 'POST',
            data: {
                displayName,
            },
            withCredentials,
        });
    };

    const deleteUserProfilePicture = async (userId: string) => {
        return await callApi({
            url: `users/${userId}/profilePicture`,
            method: 'DELETE',
            withCredentials,
        });
    };

    const putLearnerAssessmentRestart = async ({
        accessCode,
    }: {
        accessCode?: string;
    }) => {
        return await callApi<ILearnerAssessentRestartResponse>({
            url: `learners/assessments/${accessCode}/restart`,
            method: 'PUT',
            withCredentials,
        });
    };

    const getUserJobRole = async (id: string) => {
        return await callApi<{
            jobRole: string | null;
        }>({
            url: `learners/${id}/jobrole`,
            method: 'GET',
            withCredentials,
        });
    };

    const setUserJobRole = async (id: string, data: { jobRole: string }) => {
        return await callApi({
            url: `learners/${id}/jobrole`,
            method: 'PUT',
            data,
            withCredentials,
        });
    };

    const setTryingStrategy = async (userId: string, lmsSourceId: string) => {
        return await callApi({
            url: `users/${userId}/strategies/${lmsSourceId}/trying`,
            method: 'PUT',
            withCredentials,
        });
    };

    const getUserContextualisedStrategies = async (id: string) => {
        return await callApi<IContextualisedStrategiesResponse>({
            url: `userstrategies/${id}/strategies`,
            method: 'GET',
            withCredentials,
        });
    };

    const getExistingContextualisedStrategy = async (
        userId: string,
        sessionId: string
    ) => {
        return await callApi<IGetExistingContextualisedStrategyResponse>({
            url: `users/${userId}/strategies/${sessionId}/history`,
            method: 'GET',
            withCredentials,
        });
    };

    return {
        setTryingStrategy,
        getExistingContextualisedStrategy,
        getUserContextualisedStrategies,
        setUserJobRole,
        getUserJobRole,
        cognassistantQuery,
        getDomains,
        getUserDomainStrategies,
        getCognitiveMapContent,
        updateManager,
        updateSecondaryManager,
        deleteUser,
        editUser,
        getUserManagementMetaData,
        getCoaches,
        getEnterpriseUser,
        getLearningUrl,
        enrolUserToCourse,
        getUsers,
        getUser,
        getLearnerDetails,
        getCategories,
        getDomainData,
        getCategoryDeepDive,
        getAssignedModules,
        getPriorityModules,
        postLearningPlan,
        putPriorityModules,
        getFilterSkills,
        getFilterCategories,
        getUserData,
        getUserCompletedCourses,
        getLearnerAssessmentStatus,
        learnerReportViewed,
        getProcessingBiasData,
        getDashboardGraphs,
        getDiversityIndexData,
        getReportConsent,
        patchClientOnlyAllowInvitedUsers,
        patchNewReportConsent,
        putLearnerPreAssessmentDetails,
        putLearnerDetails,
        putSelfOnboardingInvitations,
        getSignupDetails,
        getHealthDataLegalDocuments,
        createLearnerOnboarding,
        checkValidEmail,
        putRegisterAssessment,
        getOnboardingLink,
        getCertifiedChampions,
        createChampionSupportRequest,
        deleteChampionSupportRequest,
        getUserChampionStatus,
        getNewChampionSupportRequestsCount,
        getNewChampionVolunteerCount,
        getInvitationMetadata,
        getSupportRequests,
        getSupportRequest,
        patchSupportRequestStatus,
        createChampionSupportRequestNote,
        getChampionUserProfile,
        getChampionProfile,
        uploadChampionProfilePicture,
        deleteChampionProfilePicture,
        canUserVolunteerAsChampion,
        volunteerAsChampion,
        approveChampionVolunteer,
        verifyEmail,
        resendVerificationEmail,
        updatePassword,
        getCognitiveMapDomainDifferenceZScores,
        getProfilePicture,
        patchChampionProfile,
        getUserNeuroVersityCourses,
        generateCommunityDisplayName,
        checkIfCommunityDisplayNameIsUsed,
        getCommunityDisplayName,
        getOrganisationCommunityDisplayName,
        getCommunityAccessToken,
        createCommunityAccount,
        deleteUserProfilePicture,
        getContextualisedStrategy,
        putLearnerAssessmentRestart,
    };
};
