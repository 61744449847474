import { ArrowUpRight, HelpCircle } from 'react-feather';
import { styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { CaiDrawer } from '../../../components/main-app/cai/CaiDrawer';
import { Button as CogButton } from '@cognassist/react-components';
import { CognassistantBanner } from '../../../components/main-app/cognitive-map/CognassistantBanner';
import { ContentHelper } from '../../../components/main-app/cognitive-map/ContentHelper';
import { DomainSidebar } from '../../../components/main-app/cognitive-map/DomainSidebar';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { RoleDrawer } from '../../../components/main-app/cognitive-map/RoleDrawer';
import SpineChart from '../../../components/main-app/cognitive-map/SpineChart';
import { WhatItMeansModal } from '../../../components/main-app/cognitive-map/WhatItMeansModal';
import { pxToRem } from '../../../utils/style-functions';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useResizeObserver from 'use-resize-observer';

const CognitiveMapMainContentInner = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(1040)]: {
        overflowX: 'auto',
    },
}));

const GraphAndHeadingWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(4),
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    justifyContent: 'center',
    [theme.breakpoints.down(774)]: {
        margin: theme.spacing(2),
    },
}));

const GraphHeading = styled('h3')(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: 400,
    marginBottom: theme.spacing(4),
}));

const PageWrapper = styled('div')(() => ({
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
}));

const MainWrapper = styled('div')(({ theme }) => ({
    flex: '1 1 100%',
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(1440)]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

const OldReportBanner = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: theme.palette.primary.main,
    padding: theme.spacing(2),
}));

const OldReportBannerText = styled(Link)(({ theme }) => ({
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    textDecoration: 'none',
}));

export const CognitiveMap = () => {
    const featureNewCognitiveMap = useFeatureIsOn('new-cognitive-map');
    const featureOldReportToggle = useFeatureIsOn('old-report-toggle');
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const {
        state: { cognitiveMap },
    } = useCognitiveMap();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    useEffect(() => {
        document.title = 'My Cognitive Map';
    }, []);

    const { width, ref } = useResizeObserver();

    const isWrapped = width && width <= 1124;

    if (!cognitiveMap) {
        return <Loading />;
    }

    if (!featureNewCognitiveMap) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <PageWrapper ref={ref}>
            {featureOldReportToggle && (
                <OldReportBanner>
                    <OldReportBannerText
                        to={`/learner/assessmentreport?learnerUserId=${userId}`}
                        target='_blank'
                        referrerPolicy='no-referrer'
                    >
                        You are viewing the new Cognitive Map, click here to
                        view the previous version.
                    </OldReportBannerText>
                    <ArrowUpRight color={theme.palette.common.white} />
                </OldReportBanner>
            )}
            <PageHeader
                title='My Cognitive Map'
                subText={
                    <ContentHelper
                        sx={{ flex: '1 1 400px' }}
                        content={cognitiveMap.staticContent.mainHeading}
                    />
                }
                action={
                    <CogButton
                        text={cognitiveMap.staticContent.explainerButton}
                        color='secondary'
                        startIcon={<HelpCircle />}
                        onClick={() => setDialogOpen(true)}
                    />
                }
            />
            <MainWrapper>
                <GraphAndHeadingWrapper>
                    <GraphHeading>
                        {cognitiveMap.staticContent.chartTitle}
                    </GraphHeading>
                    <CognitiveMapMainContentInner>
                        <SpineChart />
                    </CognitiveMapMainContentInner>
                    {!isWrapped && <CognassistantBanner />}
                </GraphAndHeadingWrapper>
                <DomainSidebar
                    topDomainText={cognitiveMap.staticContent.topDomainText}
                />
                {isWrapped && <CognassistantBanner />}
            </MainWrapper>
            <WhatItMeansModal
                cognitiveMap={cognitiveMap}
                setDialogOpen={setDialogOpen}
                dialogOpen={dialogOpen}
            />
            <RoleDrawer />
            <CaiDrawer />
        </PageWrapper>
    );
};
