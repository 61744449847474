import { ArrowRight, ChevronLeft, ChevronRight } from 'react-feather';
import {
    CircularProgress,
    IconButton as MuiIconButton,
    Typography,
    alpha,
    styled,
} from '@mui/material';
import { CognitiveMapLevel, ICognitiveDomain } from '../../../types';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import { CaiStrategyButton } from './CaiStrategyButton';
import CheckPurpleIcon from '../../../assets/img/icons/checkpurple.svg';
import { ContentHelper } from './ContentHelper';
import { DomainDrawer } from './DomainDrawer';
import { UserStrategyStatusTag } from '../cai/UserStrategyStatusTag';
import { Userpilot } from 'userpilot';
import { endpoints } from '../../../api/endpoints';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useCarousel } from '../../../custom-hooks/useCarousel';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useGrabLibraryLink } from '../../../custom-hooks/useGrabLibraryLink';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled('div')(({ theme }) => ({
    minHeight: 28,
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(0.5, 0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        cursor: 'pointer',
    },
}));

const LeftWrapper = styled('div', {
    ...shouldForwardProps('isStrength'),
})<{ isStrength?: boolean }>(({ isStrength, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexFlow: 'row nowrap',
    width: '100%',
    borderLeft: isStrength
        ? `3px solid rgba(119, 192, 0, 0.2)`
        : '3px solid #FFEBCE',
    paddingLeft: theme.spacing(1),
    gap: theme.spacing(2),
    [theme.breakpoints.down(744)]: {
        flexFlow: 'column-reverse',
        alignItems: 'flex-start',
        gap: 8,
    },
}));

const DomainTitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
}));

const DomainTitle = styled(Typography)(() => ({
    fontWeight: 600,
    fontSize: pxToRem(16),
    maxWidth: 200,
}));

export const TopStrengthIndicator = styled('div')(({ theme }) => ({
    height: 24,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(24),
    background: 'rgba(119, 192, 0, 0.2)',
    borderRadius: 50,
    padding: theme.spacing(0, 1),
    fontWeight: 600,
    whiteSpace: 'nowrap',
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    padding: theme.spacing(0.3),
    backgroundColor: theme.palette.grey[50],
    marginLeft: theme.spacing(2),
}));

const StrategiesImageWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 240,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: theme.spacing(3),
}));

const StrategiesImage = styled('img')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    transition: 'transform ease .4s',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const LoadingWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: alpha(theme.palette.grey[100], 0.5),
}));

const StrategiesTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontSize: pxToRem(18),
    fontWeight: 600,
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

const StrategiesCard = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: theme.cogShadows.lg,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
        '& .strategiesTitle': {
            color: theme.palette.primary.main,
        },
        '& .strategiesImage': {
            transform: 'scale(1.1)',
        },
    },
}));

const StrategiesWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, -3),
}));

const StrategiesCardWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    transition: 'transform 0.4s ease',
    width: '100%',
}));

const CardWrapper = styled('div')(() => ({
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
}));

const ButtonWrapper = styled('div')(() => ({
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
}));

const Back = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

const Forward = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const CarouselControls = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 3),
}));

interface IOwnProps {
    domain: ICognitiveDomain;
    topDomainText: string;
    isOpen: boolean;
}

export const Domain: React.FC<IOwnProps> = ({
    domain,
    topDomainText,
    isOpen,
}) => {
    const {
        dispatch,
        state: { openDomainDrawerId },
    } = useCognitiveMap();
    const navigate = useNavigate();

    const { grabLibraryLink } = useGrabLibraryLink();
    const [loading, setLoading] = useState<boolean>(false);
    const { enrolUserToCourse } = endpoints();

    const caiStrategiesEnabled = useFeatureIsOn('cai-strategies');

    useEffect(() => {
        return () => {
            dispatch({
                type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                payload: 0,
            });
        };
    }, []);

    const { carouselNext, carouselPrev, ref, cardStyleProps, Dots } =
        useCarousel(domain.strategies.length);

    const showControls = domain.strategies.length > 1;

    const goToStrategiesPage = () => {
        navigate('/cognitive-map/my-strategies', {
            state: { navigatedDomainId: domain.domainId },
        });
    };

    const handleClickStrategyCard = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        lmsSourceId: string
    ) => {
        if (loading) {
            return;
        }

        setLoading(true);
        const { error } = await enrolUserToCourse(lmsSourceId);

        if (!error) {
            Userpilot.track('Accessed a Course', { courseId: lmsSourceId });
            Userpilot.track(`Accessed - ${lmsSourceId}`);
            grabLibraryLink(e, `/course/${lmsSourceId}`);
        }

        setLoading(false);
    };

    return (
        <>
            <Wrapper
                onClick={() => {
                    dispatch({
                        type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                        payload:
                            openDomainDrawerId === domain.domainId
                                ? 0
                                : domain.domainId,
                    });
                }}
            >
                <LeftWrapper
                    isStrength={domain.level === CognitiveMapLevel.High}
                >
                    <DomainTitleWrapper>
                        <DomainTitle>{domain.domainName}</DomainTitle>
                    </DomainTitleWrapper>
                    {domain.topDomain && (
                        <TopStrengthIndicator>
                            {topDomainText}
                        </TopStrengthIndicator>
                    )}
                </LeftWrapper>
                <IconButton aria-label='open domain'>
                    <ChevronRight />
                </IconButton>
            </Wrapper>
            <DomainDrawer
                id={`domain-${domain.domainId}-drawer`}
                title={domain.domainName}
                close={() => {
                    dispatch({
                        type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                        payload: 0,
                    });
                }}
                open={isOpen}
                isTopStrength={domain.topDomain}
                topDomainText={topDomainText}
            >
                <ContentHelper
                    content={domain.content}
                    liIcon={
                        <img
                            alt=''
                            src={CheckPurpleIcon}
                            style={{ marginRight: 8, minWidth: 24 }}
                        />
                    }
                    pStyles={{
                        mb: 2,
                    }}
                    ulStyles={{
                        pl: 0,
                    }}
                    liStyles={{
                        mb: 2,
                        listStyle: 'none',
                        alignItems: 'center',
                        display: 'flex',
                        verticalAlign: 'middle',
                    }}
                    listWrapperStyles={{
                        mt: 3,
                    }}
                />
                <StrategiesWrapper>
                    <ContentHelper
                        content={[domain.strategiesHeading]}
                        pStyles={{
                            fontWeight: 600,
                            padding: '0 24px',
                        }}
                    />
                    <CardWrapper ref={ref}>
                        {domain.strategies.map((s, i) => (
                            <StrategiesCardWrapper
                                key={s.courseUrl}
                                sx={cardStyleProps(i)}
                            >
                                <StrategiesCard
                                    onClick={
                                        caiStrategiesEnabled
                                            ? undefined
                                            : (e) =>
                                                  handleClickStrategyCard(
                                                      e,
                                                      s.lmsSourceId
                                                  )
                                    }
                                >
                                    <StrategiesImageWrapper>
                                        <StrategiesImage
                                            className='strategiesImage'
                                            src={s.imageUrl}
                                            alt=''
                                        />
                                        {loading && (
                                            <LoadingWrapper>
                                                <CircularProgress />
                                            </LoadingWrapper>
                                        )}
                                    </StrategiesImageWrapper>
                                    {caiStrategiesEnabled && (
                                        <UserStrategyStatusTag
                                            status={s.userStrategyStatus}
                                        />
                                    )}
                                    <StrategiesTitle className='strategiesTitle'>
                                        {s.title}
                                    </StrategiesTitle>
                                    {caiStrategiesEnabled && (
                                        <CaiStrategyButton course={s} />
                                    )}
                                </StrategiesCard>
                            </StrategiesCardWrapper>
                        ))}
                    </CardWrapper>
                    <CarouselControls id='carousel-controls'>
                        {showControls && (
                            <Back>
                                <MuiIconButton
                                    onClick={carouselPrev}
                                    aria-label='carousel back'
                                >
                                    <ChevronLeft />
                                </MuiIconButton>
                            </Back>
                        )}
                        <ButtonWrapper>
                            <Button
                                text='View more'
                                endIcon={<ArrowRight />}
                                fullWidth
                                onClick={goToStrategiesPage}
                                color='secondary'
                            />
                            {showControls && <Dots />}
                        </ButtonWrapper>
                        {showControls && (
                            <Forward>
                                <MuiIconButton
                                    onClick={carouselNext}
                                    aria-label='carousel forward'
                                >
                                    <ChevronRight />
                                </MuiIconButton>
                            </Forward>
                        )}
                    </CarouselControls>
                </StrategiesWrapper>
            </DomainDrawer>
        </>
    );
};
