import { Chip, styled, useTheme } from '@mui/material';

import { Circle } from 'react-feather';
import { StrategyStatus } from '../../../types';

interface IOwnProps {
    status?: StrategyStatus;
}

const Trying = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFEA9A',
    fontWeight: 500,
}));

const New = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FEF4F8',
    fontWeight: 500,
}));

export const UserStrategyStatusTag: React.FC<IOwnProps> = ({ status }) => {
    const theme = useTheme();
    switch (status) {
        case StrategyStatus.TRYING:
            return (
                <Trying
                    label="I'm trying this"
                    icon={<Circle size={12} color='#F59C4C' fill='#F59C4C' />}
                />
            );
        case StrategyStatus.APPLIED:
        case StrategyStatus.NEW:
        default:
            return (
                <New
                    label='New'
                    icon={<Circle size={12} color={theme.palette.error.main} />}
                />
            );
    }
};
