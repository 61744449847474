import {
    AmityUiKitProvider,
    AmityUiKitSocial,
} from '@cognassist/amity-ui-toolkit';
import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { LoadingWrapper } from './shared-components';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';

const CommunityEntry: React.FC = () => {
    const { getCommunityAccessToken } = endpoints();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const {
        state: { communityDisplayName, apiKey },
        dispatch,
    } = useCommunityState();

    useEffect(() => {
        requestAccessToken();
    }, []);

    const requestAccessToken = async () => {
        const { data, error } = await getCommunityAccessToken();

        if (error) {
            setError(true);
            setLoading(false);
        }

        if (data) {
            setError(false);
            setLoading(false);
            dispatch({
                type: 'SET_KEY_AND_TOKEN',
                payload: { apiKey: data.apiKey, accessToken: data.accessToken },
            });
            return data.accessToken;
        }

        setLoading(false);
        return '';
    };

    if (loading) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    if (error) {
        return <div>An error occurred.</div>;
    }

    if (userId && apiKey && communityDisplayName) {
        return (
            <AmityUiKitProvider
                key={userId}
                apiKey={apiKey}
                apiEndpoint={{
                    http: 'https://api.eu.amity.co',
                }}
                getAuthToken={requestAccessToken}
                userId={userId}
                displayName={communityDisplayName}
                apiRegion='EU'
                socialCommunityCreationButtonVisible={false}
            >
                <AmityUiKitSocial />
            </AmityUiKitProvider>
        );
    }

    return null;
};

export default CommunityEntry;
